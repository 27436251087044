import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				NEXT LEVEL ENGLISH ACADEMY
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:title"} content={"NEXT LEVEL ENGLISH ACADEMY"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.HeaderNew>
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.HeaderNew>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="#ffffff">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					About Us
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://uploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13:57:39.717Z"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/products_thumbnail_hTbfbSl40r.jpg?v=2024-07-25T13%3A57%3A39.717Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline2" color="--dark" margin="1rem 0px 2rem 0px">
						School History
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
						Next Level English Academy was founded in 2010 by a group of enthusiasts who aimed to create quality and affordable language education for everyone. Since then, our school has come a long way, and today we are proud to have helped thousands of students achieve their language goals.
					</Text>
					<Text margin="2rem 0px 0px 0px" font="--lead" color="--dark">
						Next Level English Academy actively supports various social initiatives and charity projects. We believe that education is not only knowledge but also an opportunity to make the world a better place. Our teachers and students participate in volunteer programs, organize charity events, and help those in need.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				/>
			</LinkBox>
		</Section>
		<Section
			padding="120px 0 130px 0"
			background="linear-gradient(120deg,rgba(163, 103, 177, 0.29) 1%,rgba(57, 0, 0, 0.72) 100%) 0 0 no-repeat,#070f4f url(https://uploads.quarkly.io/66a24c319710450018a192c7/images/3-1.webp?v=2024-07-25T13:57:39.714Z) bottom/100% repeat scroll padding-box"
			lg-padding="80px 0 90px 0"
			quarkly-title="Stages/Steps-2"
			text-transform="uppercase"
		>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="40px 30px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap="26px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="--headline3">
							<Strong font="--headline2">
								Our Team
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--lightD1">
							Our team consists of highly qualified teachers with many years of experience in teaching English. Each teacher undergoes a thorough selection process and continuously improves their qualifications. We believe that the key to successful learning is a professional and individual approach to each student.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="--headline2">
							Our Achievements
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--lightD1">
							We are proud of our achievements and the results of our students. Many of them have successfully passed international exams, obtained jobs in international companies, or continued their education abroad. Our achievements are the result of the joint efforts of teachers and students, and we are always happy to see their success.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-darkL1"
		>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0 0 0">
				Teaching Methods
			</Text>
			<Text as="p" font="--headline3" margin="40px 0 20px 0">
				We use modern teaching methods based on the communicative approach. This means that during lessons we focus on developing all language skills: speaking, listening, reading, and writing. Our lessons are interesting and interactive, which helps better assimilate the material and motivates students to learn.
			</Text>
			<Link
				href="/team"
				padding="12px 24px 12\n\npx 24px"
				color="--light"
				background="--color-secondary"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="0px auto 0px auto"
				transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				sm-margin="0px 0px 0px 0px"
				sm-text-align="center"
				hover-transition="background-color 0.2s ease-in-out 0s"
				max-width="350px"
			>
				Learn More
			</Link>
		</Section>
		<Components.NewFooter1>
			<Override slot="text" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.NewFooter1>
	</Theme>;
});